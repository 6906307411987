import {Directive, effect, ElementRef, OnDestroy, Renderer2} from '@angular/core';
import {LoadingService} from "@core/services/loading.service";

@Directive({
  selector: '[appDisableButtonOnRequest]',
  standalone: true
})
export class DisableButtonOnRequestDirective implements OnDestroy {

  private showLoadingEffect = effect(() => {
      this.setButtonStatus(this.loadingService.showLoadingSig());
  });

  constructor(
    private loadingService: LoadingService,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {}

  setButtonStatus(isServerBusy: boolean) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isServerBusy);
  }

  ngOnDestroy(): void {
    this.showLoadingEffect && this.showLoadingEffect.destroy();
  }

}
